import React from 'react';

import { Layout } from '../../components/layouts';
import { SEO } from '../../components/common';
import {
  MattressWarrantyHeroSection,
  MattressWarrantyIntroSection,
  MattressWarrantyContentSection,
  MattressWarrantyFooterSection,
} from '../../components/sections';

const WoosaMattressWarranty: React.FC = () => {
  return (
    <Layout>
      <SEO title="The Woosa Mattress" />
      <MattressWarrantyHeroSection />
      <MattressWarrantyIntroSection />
      <MattressWarrantyContentSection />
      <MattressWarrantyFooterSection />
    </Layout>
  );
};

export default WoosaMattressWarranty;
